@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"












































































































$label-border: 1px dotted tint-color($color-ink-lighter, 50%)

.vertical-bar-chart
  display: grid
  grid-template-areas: "y-axis bars" ". x-axis"
  grid-template-columns: max-content  1fr
  align-items: center
  justify-items: center
  justify-content: center
  box-sizing: border-box

  &.--no-labels
    display: flex

  &__bars
    display: inline-grid
    align-items: flex-end
    justify-content: center
    grid-area: bars

    &.--y-label
      padding: 0 16px

  &__y-label
    grid-area: y-axis
    writing-mode: vertical-rl
    transform: rotate(180deg) translateY(-40px)
    padding-left: 8px
    white-space: nowrap
    border-left: $label-border
    height: 100%

  &__x-label
    grid-area: x-axis
    padding-top: 8px
    border-top: $label-border
    margin-top: 16px
    width: calc(100% + 16px)
    transform: translateX(-16px)

  &__x-label,
  &__y-label
    color: $color-ink-lighter
    +flex-center

  &__loading
    padding: 24px
    width: 100%
    height: 100%
    +flex-center
