@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



























.sas-chart-legend
  display: flex
  align-items: center
  justify-content: flex-start

  &__color
    width: 20px
    height: 20px
    border-radius: 4px
    margin-right: 8px

    +mq-s
      width: 16px
      height: 16px

  &__text
    font-size: 14px
    font-weight: 500
    line-height: 1
    margin-bottom: -1px
    color: $color-ink

