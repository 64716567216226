@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"












































































































.vertical-bar
  position: relative
  box-sizing: border-box
  transition: all 500ms ease-in-out
  background: $color-ink-light
  width: 100%

  &__container
    height: 100%
    display: inline-flex
    align-items: center
    flex-direction: column
    justify-content: flex-end
    padding-top: 24px
    box-sizing: border-box
    transition: all 400ms ease-in-out

  &__wrapper
    display: flex
    align-items: flex-end

  &__value
    font-weight: 600
    font-size: 13px
    letter-spacing: .44px
    color: $color-ink-light
    display: block
    width: 100%
    text-align: center
    position: relative
    top: -24px

  &__label
    color: $color-ink-light
    margin-top: 8px
    text-align: center
    white-space: nowrap
    font-size: $font-size-xxs
